import React, {useEffect, useState} from 'react'
import MessageQueue from 'components/MessageQueue';

function PageLayout(props) {
    const {item} = props
    const {text, component: Component} = item
    const [messageQueue, setMessageQueue] = useState([])

    useEffect(() => setMessageQueue([]), [text])

    return (
        <div>
            <h2>{text}</h2>
            <div className={text === "Overview" ? "" : "mx-4 py-0"}>
                <Component setMessageQueue={setMessageQueue} messageQueue={messageQueue}/>
            </div>
            <MessageQueue messageQueue={messageQueue} setMessageQueue={setMessageQueue}/>
        </div>
    )
}

export default PageLayout
