import dashboardPages from './dashboardPages'
import CustomSegmentRuleForm from 'app/Dashboard/Integrations/SwitchTabs/CustomSegmentRuleForm'
import Switch from 'app/Dashboard/Integrations/SwitchTabs/Switch'
import EquativForm from 'app/Dashboard/Integrations/EquativForm'
import PubmaticDomainsWhitelistForm from "../app/Dashboard/Integrations/PubmaticDomainsWhitelistForm";
import PubmaticURLsWhitelistForm from "../app/Dashboard/Integrations/PubmaticURLsWhitelistForm";

const components = {
    Pubmatic: {
        addSegment: Switch,
        editSegment: Switch,
        addCustomSegmentRule: CustomSegmentRuleForm,
        editCustomSegmentRule: CustomSegmentRuleForm,
        duplicateCustomSegmentRule: CustomSegmentRuleForm,
        createDomainsWhitelist: PubmaticDomainsWhitelistForm,
        editDomainsWhitelist: PubmaticDomainsWhitelistForm,
        createURLsWhitelist: PubmaticURLsWhitelistForm,
        editURLsWhitelist: PubmaticURLsWhitelistForm,
    },
    Equativ: {
        addEquativSegment: EquativForm,
        editEquativSegment: EquativForm,
    },
}

const integrations = dashboardPages.filter(item => item.key === "integrations")[0]

let integrationConstants = []
integrations.subItems.forEach(item => {
    let currentComponent = components[item.key];
    let keys = Object.keys(currentComponent);
    keys.forEach(key=>{
        integrationConstants.push({
            path: `${integrations.path}${item.path}/${key}`,
            text: item.text,
            component: currentComponent[key]
        })
    })
    // console.log(integrationConstants);
    // integrationConstants.push({
    //     path: integrations.path + item.path + "/add",
    //     text: item.text,
    //     component: components[item.key].add
    // })
    // integrationConstants.push({
    //     path: integrations.path + item.path + "/edit",
    //     text: item.text,
    //     component: components[item.key].edit
    // })
})


export default integrationConstants
