import {Prompt, useHistory} from "react-router-dom";
import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {pathFor} from "../../../constants/apiPaths";
import Request from 'services/Request';

function PubmaticDomainsWhitelistForm(props) {
    const {setMessageQueue} = props;
    const history = useHistory();
    const isEdit = history.location.pathname.includes('editDomainsWhitelist');
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [domains, setDomains] = React.useState('');
    const [active, setActive] = React.useState(false);
    const domainWhitelistId = isEdit ? history.location.pathname.split('/').pop() : null;

    const fetchDomainsWhitelist = async () => {
        try {
            const {
                statusCode,
                jsonResponse
            } = await Request(pathFor.pubmaticDomainsWhitelist + `?ids=${domainWhitelistId}`, {}, {}, "GET")
            if (statusCode === 200) {
                const {global_domains_whitelist} = jsonResponse
                const {name, description, domains, active} = global_domains_whitelist[0]
                setName(name)
                setDescription(description)
                setDomains(domains.join('\n'))
                setActive(active)
                // setDomainsWhitelist(global_domains_whitelist)
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch data."}])
            }
        } catch (err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }
    const clearForm = () => {
        setName('');
        setDescription('');
        setDomains('');
        setActive(false);
    }
    useEffect(async () => {
        if (isEdit) {
            await fetchDomainsWhitelist();
        }
    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const payload = {
                name,
                description,
                domains: domains.split('\n').map(domain => domain.trim()),
                active
            }
            if (isEdit) {
                payload.id = history.location.pathname.split('/').pop();
            }
            const response = isEdit ? await Request(pathFor.editPubmaticDomainsWhitelist, payload) : await Request(pathFor.createPubmaticDomainsWhitelist, payload);
            // setIsSubmitting(false);
            if (response.statusCode === 200 || response.statusCode === 201) {
                clearForm();
                setMessageQueue([{
                    success: isEdit
                        ? "Whitelist updated successfully!"
                        : "Whitelist created successfully!"
                }]);
                // Redirect after short delay
                setTimeout(() => {
                    history.push(pathFor.pubmaticHome, {tabIndex: 2});
                }, 500);
            } else {
                const errorMessage = response.jsonResponse?.errors
                    ? Object.values(response.jsonResponse.errors)[0]
                    : "An error occurred while saving the segment.";
                setMessageQueue([{error: errorMessage}]);
            }
        } catch (error) {
            console.error(error);
            setIsSubmitting(false);
        }
    }
    return (
        <>
            <Prompt
                when={!isSubmitting}
                message="You have unsaved changes. Are you sure you want to leave?"
            />
            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Domains whitelist</span>
                <FontAwesomeIcon icon={faCaretRight} className="caret-right"/>
                <strong className="ml-2">
                    {isEdit ? "Edit domains whitelist" : "Create domains whitelist"}
                </strong>
            </div>
            <div className="operation-table my-3 d-flex align-items-center">
                <p className="mb-0 me-3 push">
                    {isEdit ? "Edit domains whitelist" : "Create domains whitelist"}
                </p>
            </div>
            <div className='d-flex size-responsive'>
                <form onSubmit={handleSubmit} className='p-0 pt-responsive flex-grow-1 mr-4'>
                    <div className="mb-3">
                        <input type="text" className="form-control border py-2 px-3 w-100 rounded" id="name"
                               value={name} onChange={(e) => setName(e.target.value)}
                               placeholder="Enter name" required/>
                    </div>
                    <div className="mb-3">
                        <textarea className="form-control border py-2 px-3 w-100 rounded" id="description"
                                  value={description} onChange={(e) => setDescription(e.target.value)}
                                  placeholder="Enter description" rows="3"/>
                    </div>
                    <div className="mb-3">
                        <textarea className="form-control border py-2 px-3 w-100 rounded" id="domains"
                                  value={domains} onChange={(e) => setDomains(e.target.value)}
                                  placeholder="Enter domains"
                                  rows="5"/>
                    </div>
                    <div className="mb-3">
                        <div className="restrict">
                            <input
                                type="checkbox"
                                id="active"
                                checked={active || false}
                                onChange={(e) => setActive(e.target.checked)}
                            />
                            <label className="form-check-label ml-2" htmlFor="active">
                                Active
                            </label>
                        </div>
                    </div>
                    <button type="submit" className="btn bg--primary text--white px-3">
                        {isEdit ? "Update" : "Create"}
                    </button>
                </form>
            </div>
        </>
    )
}

export default PubmaticDomainsWhitelistForm
