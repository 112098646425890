import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import DatasetFilters from './CustomSegRuleFormFiles/DatasetFilters';
import PreviewPane from './CustomSegRuleFormFiles/PreviewPane';
import Keywords, {ExcludeKeywords} from './CustomSegRuleFormFiles/Components/Keywords';
import TaxonomyDropdown from './CustomSegRuleFormFiles/TaxonomyDropdown';
import AdvertiserDomains from './CustomSegRuleFormFiles/AdvertiserDomains';
import PublisherDomains from './CustomSegRuleFormFiles/PublisherDomains';
import { Snackbar } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { languageMappings } from './CustomSegRuleFormFiles/mappings';
import { pathFor } from 'constants/apiPaths';
import Request from 'services/Request';

function CustomSegmentRuleForm(props) {
    const { dashboardType, setMessageQueue, messageQueue } = props;
    const history = useHistory();

     // Existing state variables
     const [isSubmitting, setIsSubmitting] = useState(false);
     const [customSegmentId, setCustomSegmentId] = useState();
     const [segmentPrice, setSegmentPrice] = useState(0);
     const [enabled, setEnabled] = useState(false);
     const [taxonomyList, setTaxonomyList] = useState([]);
     const [taxonomies, setTaxonomies] = useState([]);
     const [keywords, setKeywords] = useState("");
     const [excludeKeywords, setExcludeKeywords] = useState("");
     const [languages, setLanguages] = useState([]);
     const [emotions, setEmotions] = useState([]);
     const [intents, setIntents] = useState([]);
     const [extras, setExtras] = useState({
         domains_whitelist: "",
         domains_blacklist: ""
     });
     const [candidate, setCandidate] = useState([]);
     const [sensationalfactbased, setSensationalFactBased] = useState([]);
     const [liberalconservative, setLiberalConservative] = useState([]);
     const [brandsafety, setBrandSafety] = useState([]);
     const [segmentName, setSegmentName] = useState("");
     const [segmentSourceId, setSegmentSourceId] = useState("");
     const [condition, setCondition] = useState(false);
     const [adKernelCampaignIds, setAdKernelCampaignIds] = useState("");


     // New state for form dirty tracking
     const [isFormDirty, setIsFormDirty] = useState(false);

    // Determine if the view is for a publisher and if the form is in edit mode.
    const isPublisher = history.location.pathname.endsWith("publisher-view");
    const isEdit = history.location.pathname.includes("editCustomSegmentRule");
    const isDuplicate = history.location.pathname.includes("duplicateCustomSegmentRule");
    const customSegmentRuleId = isEdit || isDuplicate ? history.location.pathname.split('/').splice(5)[0] : null;

       // Existing props setup
       const keywordsProps = { keywords, setKeywords };
       const excludeKeywordsProps = { excludeKeywords, setExcludeKeywords };
       const taxonomyProps = { taxonomies, setTaxonomies, taxonomyList };
       const domainsProps = { extras, setExtras, dashboardType };
       const DomainsComponent = isPublisher ? PublisherDomains : AdvertiserDomains;

       const features = {
        keywords, taxonomies, languages, candidate, emotions,
        intents, sensationalfactbased, liberalconservative, brandsafety,
        segmentName, segmentSourceId
    };

    const previewPaneProps = {
        ...features,
        setKeywords, setCandidate, setLanguages, setEmotions,
        setIntents, setSensationalFactBased, setLiberalConservative,
        setBrandSafety, setSegmentName, setSegmentSourceId,
        setTaxonomies, setTaxonomyList
    };

    // Check for form changes
    useEffect(() => {
        const hasChanges = [
            intents.length > 0,
            emotions.length > 0,
            languages.length > 0,
            taxonomies.length > 0,
            keywords.trim().length > 0,
            candidate.length > 0,
            sensationalfactbased.length > 0,
            liberalconservative.length > 0,
            brandsafety.length > 0,
            extras.domains_whitelist.trim().length > 0,
            extras.domains_blacklist.trim().length > 0,
            segmentName.length > 0
        ].some(Boolean);

        setIsFormDirty(hasChanges);
    }, [
        intents,
        emotions,
        languages,
        taxonomies,
        keywords,
        candidate,
        sensationalfactbased,
        liberalconservative,
        brandsafety,
        extras.domains_whitelist,
        extras.domains_blacklist,
        segmentName
    ]);

// Fetch available taxonomies.
useEffect(() => {
    const fetchTaxonomies = async () => {
        try {
            const response = await Request(pathFor.taxonomies, {}, {}, "GET");
            if (response.statusCode === 200 && response.jsonResponse?.data) {
                setTaxonomyList(response.jsonResponse.data);
            } else {
                setMessageQueue([...messageQueue,{ error: "Failed to fetch taxonomies." }]);
            }
        } catch (error) {
            console.error("Error fetching taxonomies:", error);
            setMessageQueue([{ error: "Error fetching taxonomies." }]);
        }
    };
    fetchTaxonomies();
}, []);  // No dependencies here as this should run once when the component mounts.


// Update the fetchSegmentData function in your useEffect:
useEffect(() => {
    const fetchSegmentData = async () => {
        if (!customSegmentRuleId) return;

        try {
            const response = await Request(pathFor.customsegmentrules + `?ids=${customSegmentRuleId}`, {}, {}, "GET");

            if (response.statusCode === 200 && response.jsonResponse?.segments) {
                const rule = response.jsonResponse.segments.find(
                    segment => segment._id === customSegmentRuleId
                );

                if (rule) {
                    // Update form fields with existing data
                    if (isEdit) {
                        setSegmentName(rule.name || '');
                        setSegmentSourceId(rule.providerAudienceId || '');
                        setSegmentPrice(rule.price || 0);
                        setEnabled(rule.enabled || false);
                        setCustomSegmentId(rule.pubmatic_segment_id);

                        if (rule.ad_kernel_campaign_ids){
                            setAdKernelCampaignIds(rule.ad_kernel_campaign_ids.join(', '));
                        }
                    }

                    // Handle rule object data
                    if (rule.rule) {
                        // Handle keywords
                        setKeywords(Array.isArray(rule.rule.keywords) ? rule.rule.keywords.join('\n') : '');
                        setExcludeKeywords(Array.isArray(rule.rule.exclude_keywords) ? rule.rule.exclude_keywords.join('\n') : '');

                        // Handle languages with mapping
                        const mappedLanguages = rule.rule.languages?.map(langCode =>
                            Object.keys(languageMappings).find(key =>
                                languageMappings[key] === langCode
                            )
                        ).filter(Boolean) || [];
                        setLanguages(mappedLanguages);

                        // Handle other arrays from rule object
                        setEmotions(rule.rule.sentiments || []);
                        setIntents(rule.rule.intents || []);
                        setCandidate(rule.rule.candidates || []);
                        setSensationalFactBased(rule.rule.sensationalist_fact_based || []);
                        setLiberalConservative(rule.rule.liberal_conservative || []);
                        setBrandSafety(rule.rule.brand_safety ? rule.rule.brand_safety : []);
                        setCondition(rule.rule.condition === 'AND');

                        // Handle taxonomies
                        setTaxonomies(rule.rule.taxonomies || []);

                        // Handle domains
                        setExtras({
                            domains_whitelist: Array.isArray(rule.rule.domains_whitelist)
                                ? rule.rule.domains_whitelist.join('\n')
                                : '',
                            domains_blacklist: Array.isArray(rule.rule.domains_blacklist)
                                ? rule.rule.domains_blacklist.join('\n')
                                : ''
                        });
                    }

                    // Reset form dirty state after loading
                    setIsFormDirty(false);
                } else {
                    setMessageQueue([{ error: "Segment rule not found" }]);
                }
            } else {
                setMessageQueue([{ error: "Failed to fetch segment data" }]);
            }
        } catch (error) {
            console.error("Error fetching segment data:", error);
            setMessageQueue([{ error: "Error loading segment data. Please try again." }]);
        }
    };

    fetchSegmentData();
}, [customSegmentRuleId, languageMappings]); // Added languageMappings to dependencies

    const validateForm = () => {
        let errorMessages = [];
        if (segmentName.trim().length === 0) errorMessages.push("Please enter a segment name.");
        if (segmentSourceId.trim().length === 0) errorMessages.push("Please enter a provider audience ID.");
        if (segmentPrice <= 0) errorMessages.push("Please enter a valid price.");
        return errorMessages;
    }

    // Enhanced form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isSubmitting) return;
        let errorMessages = validateForm()
        if(errorMessages.length > 0) {
            setMessageQueue(errorMessages.map((msg) => {
                return {error: msg}
            }));
            return;
        }

        setIsSubmitting(true);

        const payload = {
            name: Array.isArray(segmentName) ? segmentName[0] : segmentName,
            providerAudienceId: segmentSourceId,
            price: parseFloat(segmentPrice),
            enabled: enabled,
            taxonomies,
            intents,
            sentiments: emotions,
            languages: languages.map(lang => languageMappings[lang]),
            keywords: keywords.split('\n').map(k => k.trim()).filter(Boolean),
            exclude_keywords: excludeKeywords.split('\n').map(k => k.trim()).filter(Boolean),
            condition: condition ? "AND" : "OR",
            domains_whitelist: extras.domains_whitelist.split('\n').map(d => d.trim()).filter(Boolean),
            domains_blacklist: extras.domains_blacklist.split('\n').map(d => d.trim()).filter(Boolean),
            sensationalist_fact_based: sensationalfactbased,
            liberal_conservative: liberalconservative,
            candidates: candidate,
            brand_safety: brandsafety
        };

        if (adKernelCampaignIds) {
            payload.ad_kernel_campaign_ids = adKernelCampaignIds.split(',').map(c => c.trim()).filter(Boolean);
        }

        try {
            const response = isEdit
                ? await Request(pathFor.editCustomSegmentRules, payload, {}, 'POST')
                : await Request(pathFor.createPubmaticSegmentRules, payload, {}, 'POST');
                console.log("Payload:", JSON.stringify(payload, null, 2));

            if (response.statusCode === 200 || response.statusCode === 201) {
                setMessageQueue([{
                    success: isEdit
                        ? "Segment updated successfully!"
                        : "Segment created successfully!"
                }]);
                setIsFormDirty(false);

                // Redirect after short delay
                setTimeout(() => {
                    history.push(pathFor.pubmaticHome, {tabIndex: 0});
                }, 1500);
            } else {
                const errorMessage = response.jsonResponse?.error
                    ? Object.values(response.jsonResponse.error)
                    : "An error occurred while saving the segment.";
                setMessageQueue([{ error: errorMessage }]);
            }
        } catch (error) {
            console.error("Error saving segment:", error);
            setMessageQueue([{ error: "Server error. Please try again later." }]);
        } finally {
            setIsSubmitting(false);
        }
    };

    const resetForm = () => {
        setKeywords("");
        setExcludeKeywords("");
        setLanguages([]);
        setEmotions([]);
        setIntents([]);
        setSensationalFactBased([]);
        setLiberalConservative([]);
        setBrandSafety([]);
        setCandidate([]);
        setSegmentName("");
        setSegmentSourceId("");
        setExtras({ domains_whitelist: "", domains_blacklist: "" });
        setIsFormDirty(false);
    };

    const handleCancel = () => {
        if (!isFormDirty || window.confirm('Are you sure you want to leave? Your changes will be lost.')) {
            history.goBack();
        }
    };

    return (
        <>

            <Prompt
                when={isFormDirty && !isSubmitting}
                message="You have unsaved changes. Are you sure you want to leave?"
            />

            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Create Custom Segment Rule</span>
                <FontAwesomeIcon icon={faCaretRight} className="caret-right" />
                <strong className="ml-2">
                    {isEdit ? "Edit Custom Segment Rule" : "Add Custom Segment Rule"}
                </strong>
            </div>

            <div className="operation-table my-3 d-flex align-items-center">
    <p className="mb-0 me-3 push">
        {isEdit ? "EDIT CUSTOM SEGMENT RULE" : "ADD CUSTOM SEGMENT RULE"}
    </p>

    <div className="form-check">
        <input
            type="checkbox"
            className="form-check-input"
            id="enabled"
            checked={enabled}
            onChange={e => setEnabled(e.target.checked)}
        />
        <label className="form-check-label" htmlFor="enabled" id='text-label'>
            Enable Segment
        </label>
    </div>
</div>

            <div className='d-flex size-responsive'>
                <form onSubmit={handleSubmit} className='p-0 pt-responsive flex-grow-1 mr-4'>
                <DatasetFilters
                    features={{
                        "Segment Name": {
                            selected: segmentName,
                            setSelected: setSegmentName,
                            inputField: true,
                        },
                        "Provider Audience ID": {
                            selected: segmentSourceId,
                            setSelected: isEdit ? undefined : setSegmentSourceId, // Disable editing in edit mode
                            inputField: true,
                            disabled: isEdit, // Add readOnly prop
                        },
                        "Price": {
                            selected: segmentPrice,
                            setSelected: setSegmentPrice,
                            inputField: true,
                            type: "number",
                            placeholder: "Enter the price in USD",
                            step: "0.01",
                            min: "0",
                        },
                    }}
                />

                    <hr style={{ margin: '10px 0', border: '1px solid #ccc', marginTop:'1px'}} />

                    <TaxonomyDropdown {...taxonomyProps} />

                    <DatasetFilters
                        features={{
                            Language: {
                                selected: languages,
                                setSelected: setLanguages,
                                multi: true,
                                mappings: languageMappings
                            },
                            Intent: {
                                selected: intents,
                                setSelected: setIntents,
                                multi: true
                            }
                        }}
                    />

                    <DatasetFilters
                        features={{
                            Sentiment: {
                                selected: emotions,
                                setSelected: setEmotions,
                                multi: true
                            },
                            Candidate: {
                                selected: candidate,
                                setSelected: setCandidate,
                                multi: true
                            }
                        }}
                    />

                    <Keywords {...keywordsProps} />
                    <ExcludeKeywords {...excludeKeywordsProps}/>

                    <DatasetFilters
                        features={{
                            "Sensational Fact Based": {
                                selected: sensationalfactbased,
                                setSelected: setSensationalFactBased,
                                multi: true
                            },
                            "Liberal Conservative": {
                                selected: liberalconservative,
                                setSelected: setLiberalConservative,
                                multi: true
                            },
                            "Brand Safety": {
                                selected: brandsafety,
                                setSelected: setBrandSafety,
                                multi: true
                            }
                        }}
                    />

                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="condition"
                            checked={condition}
                            onChange={e => setCondition(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="condition" id='text-label'>
                            AND Condition
                        </label>
                    </div>


                    <DomainsComponent {...domainsProps}
                    extras={extras}
                    setExtras={setExtras}
                    />
                    <div className='mb-responsive'>
                        <span className="label-text d-flex justify-content-between pb-0 pt-2 mb-0">AdKernel Campaign IDs</span>
                        <div className='flex-grow-1 mt-2'>
                            <input
                                type="text" // Use "text" as default type
                                className="border py-2 px-3 w-100 rounded"
                                placeholder="Enter AdKernel Campaign Ids"
                                value={adKernelCampaignIds}
                                onChange={(e) => setAdKernelCampaignIds(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mb-3">
                    <button
                            type="button"
                            className="btn bg-white px-3 py-2 btn-cancel text--primary"
                            onClick={() => history.goBack()}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn bg--primary text--white py-2 px-3 ml-3"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? "Submitting..." : (isEdit ? "Make Changes" : "Submit")}
                        </button>
                    </div>
                </form>

                <PreviewPane className="preview-pane" {...previewPaneProps} />
            </div>
        </>
    );
}

export default CustomSegmentRuleForm;
