import React, {useEffect, useState} from "react";
import {tabConstants} from "../../../constants/pubmaticTabConstants";
import {useHistory, useLocation} from "react-router-dom";

function Pubmatic(props) {
    const {setMessageQueue} = props
    const location = useLocation();
    const history = useHistory();
    const [tabStates, setTabStates] = useState({})
    const [activeTab, setActiveTab] = useState(Object.keys(tabConstants)[location.state?.tabIndex || 0])
    const Component = tabConstants[activeTab]

    const updateTabStates = value => {
        const tempStates = {...tabStates}
        tempStates[activeTab] = value
        setTabStates(tempStates)
    }

    useEffect(() => {
        history.replace({pathname: location.pathname, state: {tabIndex: Object.keys(tabConstants).indexOf(activeTab)}});
    }, [activeTab])

    return (
        <div className="mb-4">
            <div className="d-flex justify-content-around tab-section-border mb-3">
                {
                    Object.keys(tabConstants).map((tab, idx) => (
                        <div className="d-flex flex-column justify-content-start tabs" key={idx}>
                            <button
                                type="button"
                                className={`bg-white pb-3 px-4 border-0 tab${tab === activeTab && "-active"}`}
                                onClick={e => setActiveTab(e.target.innerText)}
                            >
                                <span className="px-1">{tab}</span>
                            </button>
                            {
                                tab === activeTab && (
                                    <div className="bg-white p-0 outline-active"></div>
                                )
                            }
                        </div>
                    ))
                }
            </div>
            <Component currentState={tabStates[activeTab]} setCurrentState={updateTabStates}
                       setMessageQueue={setMessageQueue}/>
        </div>
    )
}

export default Pubmatic
