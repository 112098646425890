import React from 'react'
import Snackbar from './Snackbar'

function MessageQueue({messageQueue, setMessageQueue}) {
    const CloseHandle = (msg) => {
        setMessageQueue(messageQueue.filter((m) => m !== msg))
    }

    return (
        <div className={messageQueue && messageQueue.length ? "message-queue m-4" : "d-none"}>
            {
                messageQueue.map((msg,inx) => (
                    <Snackbar key={inx} msg={msg} closeHandle={CloseHandle}/>
                ))
            }
        </div>
    )
}

export default MessageQueue
