import React from 'react'
import TextArea from './TextArea'


function Keywords(props) {
    const { keywords, setKeywords } = props

    return (
        <div className='p-0 mb-responsive'>
            <TextArea
                rows="10"
                className="rounded"
                id="keywords-custom-query"
                label="Keywords"
                placeholder="Paste/type your keywords here."
                onChange={e => setKeywords(e.target.value)}
                value={ keywords }
            />
        </div>
    )
}

export function ExcludeKeywords(props) {
    const { excludeKeywords, setExcludeKeywords } = props

    return (
        <div className='p-0 mb-responsive'>
            <TextArea
                rows="3"
                className="rounded"
                id="exclude-keywords-custom-query"
                label="Exclude Keywords"
                placeholder="Paste/type your exclude keywords here."
                onChange={e => setExcludeKeywords(e.target.value)}
                value={ excludeKeywords }
            />
        </div>
    )
}

export default Keywords
